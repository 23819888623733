import React, { useState } from "react";
import { firestore } from "../../firebase"; // Ensure Firestore is imported correctly
import { collection, addDoc } from "firebase/firestore"; // Firestore methods for adding data
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Alert from "../../Components/Alert";

const JobRequirementForm = () => {
    const  location = useLocation()
    const [showSuccess ,setShowSucces] = useState(false)
    
     const jobTitle = location.state.jobTitle
  const [formData, setFormData] = useState({
    jobTitle: "",
    department: "",
    experience: "",
    skills: "",
    responsibilities: "",
    qualifications: "",
    location: "",
    resume: null, // Added field for resume
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      resume: files[0], // Store the selected file
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    
      // Add the form data to Firestore (excluding the file)
      try {
                 // Add the form data to Firestore
                 await addDoc(collection(firestore, "userInfoForJobRequirement"), {
        jobTitle: formData.jobTitle,
        department: formData.department,
        experience: formData.experience,
        skills: formData.skills,
        responsibilities: formData.responsibilities,
        qualifications: formData.qualifications,
        location: formData.location,
        timestamp: new Date(), // Add a timestamp
      });

      // Handle the file upload (e.g., Firebase Storage, etc.) if needed
      if (formData.resume) {
        // You can upload the resume to Firebase Storage or any other server here
        // Example (assuming you've set up Firebase Storage):
        // const storageRef = ref(storage, `resumes/${jobDoc.id}.pdf`);
        // await uploadBytes(storageRef, formData.resume);
      }

      alert("Job requirement submitted successfully!");
      setFormData({
        jobTitle: "",
        department: "",
        experience: "",
        skills: "",
        responsibilities: "",
        qualifications: "",
        location: "",
        resume: null, // Reset the resume
      }); // Clear the form
    } catch (error) {
      console.error("Error sending job requirement: ", error);
      alert("An error occurred. Please try again.");
    }
  };

 const {t} = useTranslation();

  return (
    <div style={styles.container}>
      <h2 style={styles.sectionTitle}>{t('Job Requirement Form')}</h2>
      <div style={styles.formWrapper}>
        {/* Contact Info Section */}
        <div style={styles.contactInfo}>
          <h2 style={styles.sectionTitle}>{t('Get in Touch')}</h2>
  
          <div style={styles.contactInfoItem}>
            <div style={styles.icon}>📍</div>
            <div style={styles.infoContent}>
              <h3 style={styles.infoTitle}>{t('Address')}</h3>
              <p style={styles.infoText}>1st Floor, 146, Dhan Mandi, HMO, Rajasthan, India</p>
            </div>
          </div>
  
          <div style={styles.contactInfoItem}>
            <div style={styles.icon}>📞</div>
            <div style={styles.infoContent}>
              <h3 style={styles.infoTitle}>{t('Phone')}</h3>
              <p style={styles.infoText}>+91 8442002022</p>
            </div>
          </div>
  
          <div style={styles.contactInfoItem}>
            <div style={styles.icon}>✉️</div>
            <div style={styles.infoContent}>
              <h3 style={styles.infoTitle}>{t('Email')}</h3>
              <p style={styles.infoText}>support@studybuddy.education</p>
            </div>
          </div>
        </div>
  
        {/* Job Requirement Form */}
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="jobTitle" style={styles.label}>{t('Job Title')}</label>
            <input
              style={styles.input}
              type="text"
              id="jobTitle"
              name="jobTitle"
              value={jobTitle}
              // onChange={handleChange}
              disabled
            />
          </div>
  
          <div style={styles.formGroup}>
            <label htmlFor="department" style={styles.label}>{t('Department')}</label>
            <select
              style={styles.input}
              id="department"
              name="department"
              value={formData.department}
              onChange={handleChange}
              required
            >
              <option value="">{t('Select department')}</option>
              <option value="engineering">{t('Technical')}</option>
              <option value="marketing">{t('Marketing')}</option>
              <option value="sales">{t('Sales')}</option>
              {/* <option value="hr">Human Resources</option> */}
              <option value="other">{t('Other')}</option>
            </select>
          </div>
  
          <div style={styles.formGroup}>
            <label htmlFor="experience" style={styles.label}>{t('Experience (years)')}</label>
            <input
              style={styles.input}
              type="number"
              id="experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              required
            />
          </div>
  
          <div style={styles.formGroup}>
            <label htmlFor="skills" style={styles.label}>{t('Skills')}</label>
            <textarea
              style={styles.textarea}
              id="skills"
              name="skills"
              value={formData.skills}
              onChange={handleChange}
              required
            />
          </div>
  
          {/* <div style={styles.formGroup}>
            <label htmlFor="responsibilities" style={styles.label}>Job Responsibilities</label>
            <textarea
              style={styles.textarea}
              id="responsibilities"
              name="responsibilities"
              value={formData.responsibilities}
              onChange={handleChange}
              required
            />
          </div> */}
  
          <div style={styles.formGroup}>
            <label htmlFor="qualifications" style={styles.label}>{t('Educational Qualifications')}</label>
            <input
              style={styles.input}
              type="text"
              id="qualifications"
              name="qualifications"
              value={formData.qualifications}
              onChange={handleChange}
              required
            />
          </div>
  
          <div style={styles.formGroup}>
            <label htmlFor="location" style={styles.label}>{t('Location')}</label>
            <input
              style={styles.input}
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            />
          </div>
  
          {/* Resume Upload */}
          <div style={styles.formGroup}>
            <label htmlFor="resume" style={styles.label}>{t('Upload Resume')}</label>
            <input
              style={styles.fileInput}
              type="file"
              id="resume"
              name="resume"
              onChange={handleFileChange}
              accept=".pdf,.docx,.txt"
            />
          </div>
  
          <div style={styles.formActions}>
            <button type="button" style={styles.cancelBtn} onClick={() => setFormData({
              jobTitle: "",
              department: "",
              experience: "",
              skills: "",
              responsibilities: "",
              qualifications: "",
              location: "",
              resume: null, // Reset the resume
            })}>
              {t('Cancel')}
            </button>
            <button type="submit" style={styles.submitBtn}>
              {t('Submit')}
            </button>
          </div>
        </form> 
        {showSuccess && (
          <Alert
          type="success" 
            message="Job requirement submitted successfully!"
            show={showSuccess}
            onClose={() => setShowSucces(false)}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "2rem",
  },
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "1.5rem",
    textAlign: "center",
  },
  formWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2rem",
  },
  contactInfo: {
    flex: 1,
    minWidth: "300px",
    background: "#fff",
    padding: "2rem",
    borderRadius: "10px",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
  },
  contactInfoTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "1.5rem",
  },
  contactInfoItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.5rem",
  },
  icon: {
    width: "40px",
    height: "40px",
    background: "#3498db",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "1rem",
    color: "white",
  },
  infoContent: {},
  infoTitle: {
    fontWeight: "bold",
    marginBottom: "0.3rem",
  },
  infoText: {
    color: "#666",
  },
  form: {
    flex: 2,
    minWidth: "300px",
    background: "#fff",
    padding: "2rem",
    borderRadius: "10px",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
  },
  formGroup: {
    marginBottom: "1.5rem",
  },
  label: {
    display: "block",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#333",
    marginBottom: "0.5rem",
  },
  input: {
    width: "100%",
    padding: "0.8rem",
    border: "1px solid #ddd",
    borderRadius: "5px",
    fontSize: "1rem",
    transition: "border-color 0.3s ease",
  },
  fileInput: {
    width: "100%",
    padding: "0.8rem",
    border: "1px solid #ddd",
    borderRadius: "5px",
    fontSize: "1rem",
  },
  textarea: {
    width: "100%",
    padding: "0.8rem",
    border: "1px solid #ddd",
    borderRadius: "5px",
    fontSize: "1rem",
    transition: "border-color 0.3s ease",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
  },
  cancelBtn: {
    padding: "0.8rem 1.5rem",
    backgroundColor: "#f1f1f1",
    border: "1px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
  },
  submitBtn: {
    padding: "0.8rem 2rem",
    backgroundColor: "#3498db",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
  },
};

export default JobRequirementForm;
