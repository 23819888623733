import React, { useState, useRef, useEffect } from 'react';
import { Bell, MessageSquare, X, Send, Paperclip, Mic, Video, Square, Trash2, ChevronDown, ChevronUp } from 'lucide-react';

const MOCK_NOTIFICATIONS = [
  { id: 1, text: 'New comment on your post' },
  { id: 2, text: 'You have a new follower' },
  { id: 3, text: 'Your password was changed' },
];

const MOCK_MESSAGES = [
  { id: 1, sender: 'Alice', text: 'Hi, how are you?', media: null },
  { id: 2, sender: 'Bob', text: 'Check out this video', media: null },
];

const Chat = () => {
  // Sidebar state
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isMaximized, setIsMaximized] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');

  // Media recording state
  const [replyText, setReplyText] = useState('');
  const [replyMedia, setReplyMedia] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingType, setRecordingType] = useState(null);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState({ audio: false, video: false });

  // Refs
  const mediaRecorder = useRef(null);
  const videoPreview = useRef(null);
  const chunks = useRef([]);
  const timerRef = useRef();
  const streamRef = useRef(null);

  useEffect(() => {
    return () => {
      stopRecording();
      clearInterval(timerRef.current);
    };
  }, []);

  const filteredMessages = MOCK_MESSAGES.filter(
    (message) =>
      message.sender.toLowerCase().includes(searchText.toLowerCase()) ||
      message.text.toLowerCase().includes(searchText.toLowerCase())
  );

  const checkPermission = async (type) => {
    try {
      const result = await navigator.permissions.query({ 
        name: type === 'audio' ? 'microphone' : 'camera' 
      });
      setPermissions(prev => ({ ...prev, [type]: result.state === 'granted' }));
      return result.state === 'granted';
    } catch (err) {
      console.error('Permission check failed:', err);
      return false;
    }
  };

  const requestPermission = async (type) => {
    try {
      const constraints = {
        audio: type === 'audio' || type === 'video',
        video: type === 'video'
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      stream.getTracks().forEach(track => track.stop());
      setPermissions(prev => ({ ...prev, [type]: true }));
      setError(null);
      return true;
    } catch (err) {
      setError(`Please allow ${type} access to record`);
      return false;
    }
  };

  const startRecording = async (type) => {
    try {
      const hasPermission = await checkPermission(type);
      if (!hasPermission) {
        const granted = await requestPermission(type);
        if (!granted) return;
      }

      const constraints = {
        audio: true,
        video: type === 'video'
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream;

      if (type === 'video' && videoPreview.current) {
        videoPreview.current.srcObject = stream;
      }

      mediaRecorder.current = new MediaRecorder(stream);
      chunks.current = [];

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(chunks.current, { type: `${type}/webm` });
        setReplyMedia({
          type,
          url: URL.createObjectURL(blob),
          name: `${type}-recording-${new Date().getTime()}.webm`
        });
        
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
        }
        setIsRecording(false);
        setRecordingType(null);
        setDuration(0);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
      setRecordingType(type);
      setError(null);

      timerRef.current = window.setInterval(() => {
        setDuration(d => d + 1);
      }, 1000);
    } catch (err) {
      setError(`Error accessing ${type} device. Please try again.`);
      console.error(`Error accessing ${type}:`, err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      clearInterval(timerRef.current);
      
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const mediaType = file.type.startsWith('audio') 
        ? 'audio' 
        : file.type.startsWith('video') 
          ? 'video' 
          : 'document';
      
      setReplyMedia({ 
        type: mediaType, 
        url: URL.createObjectURL(file),
        name: file.name
      });
    }
  };

  const handleSend = () => {
    if (replyText.trim() || replyMedia) {
      console.log('Sending:', { text: replyText, media: replyMedia });
      setReplyText('');
      setReplyMedia(null);
    }
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };
  const styles = {
    overlay: {
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    },
    sidebar: {
      position: 'fixed',
      right: 0,
      top: 0,
      height: '100%',
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      width: '360px',
    },
    header: {
      padding: '16px',
      borderBottom: '1px solid #e5e7eb',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerTitle: {
      fontSize: '1.25rem',
      fontWeight: '600',
    },
    closeButton: {
      padding: '4px',
      borderRadius: '50%',
      marginRight:"10px",
      border:"none",
      transition: 'background-color 0.2s',
    },
    closeButtonHover: {
      backgroundColor: '#f3f4f6',
    },
    filterContainer: {
      padding: '16px',
    },
    filterSelect: {
      width: '100%',
      padding: '8px',
      border: '1px solid #e5e7eb',
      borderRadius: '8px',
    },
    contentContainer: {
      height: 'calc(100% - 180px)',
      overflowY: 'auto',
      padding: '16px',
    },
    sectionTitle: {
      fontSize: '1.125rem',
      fontWeight: '600',
      marginBottom: '16px',
    },
    notificationList: {
      marginBottom: '24px',
    },
    notificationItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      padding: '12px',
      backgroundColor: '#f9fafb',
      borderRadius: '8px',
      marginBottom: '12px',
      transition: 'background-color 0.2s',
    },
    notificationItemHover: {
      backgroundColor: '#f3f4f6',
    },
    notificationDot: {
      position: 'absolute',
      top: '-4px',
      right: '-4px',
      width: '8px',
      height: '8px',
      backgroundColor: '#ef4444',
      borderRadius: '50%',
    },
    searchInput: {
      width: '100%',
      padding: '8px',
      border: '1px solid #e5e7eb',
      borderRadius: '8px',
      marginBottom: '16px',
    },
    messageItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      padding: '12px',
      backgroundColor: '#f9fafb',
      borderRadius: '8px',
      marginBottom: '12px',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
    },
    messageItemHover: {
      backgroundColor: '#f3f4f6',
    },
    messageAvatar: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: '#1e40af',
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    messageSender: {
      fontWeight: '600',
    },
    messageText: {
      fontSize: '0.875rem',
      color: '#4b5563',
    },
    fixedContainer: {
      position: 'fixed',
      bottom: '1rem',
      right: '370px',
      backgroundColor: '#ffffff',
      borderRadius: '0.5rem',
      boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
      zIndex: 50,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      backgroundColor: '#1E40AF',
      color: '#ffffff',
      padding: '0.75rem',
      borderTopLeftRadius: '0.5rem',
      borderTopRightRadius: '0.5rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    headerButton: {
      padding: '0.25rem',
      borderRadius: '0.25rem',
      transition: 'background-color 0.2s',
    },
    headerButtonHover: {
      backgroundColor: '#1E3A8A',
    },
    messageContent: {
      flex: 1,
      padding: '1rem',
      overflowY: 'auto',
    },
    messageItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
    },
    messageAvatar: {
      width: '2rem',
      height: '2rem',
      borderRadius: '50%',
      backgroundColor: '#DBEAFE',
      color: '#1E40AF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    messageSender: {
      fontWeight: '500',
      color: '#1F2937',
    },
    messageText: {
      marginTop: '0.25rem',
      color: '#4B5563',
    },
    mediaContainer: {
      marginTop: '0.75rem',
    },
    mediaLink: {
      color: '#1E40AF',
      textDecoration: 'none',
      transition: 'color 0.2s',
    },
    mediaLinkHover: {
      color: '#1E3A8A',
    },
    replyBox: {
      padding: '1rem',
      borderTop: '1px solid #E5E7EB',
    },
    replyTextarea: {
      width: '100%',
    //   padding: '0.75rem',
      border: '1px solid #E5E7EB',
      borderRadius: '0.5rem',
      fontFamily:"raleway",
      marginBottom: '1rem',
      height: '2rem',
      resize: 'none',
      outline: 'none',
      transition: 'border-color 0.2s',
    },
    replyTextareaFocus: {
      borderColor: '#3B82F6',
    },
    errorMessage: {
      marginBottom: '1rem',
      fontSize: '0.875rem',
      color: '#DC2626',
      backgroundColor: '#FEE2E2',
      padding: '0.5rem',
      borderRadius: '0.5rem',
    },
    recordingContainer: {
      marginBottom: '5rem',
      backgroundColor: '#FEE2E2',
      padding: '0.5rem',
      borderRadius: '0.5rem',
      // display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    recordingIndicator: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    recordingDot: {
      width: '0.5rem',
      height: '0.5rem',
      borderRadius: '50%',
      backgroundColor: '#DC2626',
      animation: 'pulse 1s infinite',
    },
    recordingText: {
      color: '#DC2626',
    },
    recordingButtons: {
      display: 'flex',
      gap: '0.5rem',
      borderRadius:"50%"
    },
    recordingButton: {
      padding: '0.25rem',
      borderRadius: '0.25rem',
      transition: 'background-color 0.2s',
      
    },
    recordingButtonHover: {
      backgroundColor: '#FECACA',
    },
    mediaPreview: {
      marginBottom: '1rem',
    },
    removeMediaButton: {
      marginTop: '0.5rem',
      fontSize: '0.875rem',
      color: '#DC2626',
      transition: 'color 0.2s',
    },
    removeMediaButtonHover: {
      color: '#B91C1C',
    },
    replyActions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    replyActionButton: {
      padding: '0.5rem',
      color: '#6B7280',
      borderRadius:"50%",
      border:"none",
      transition: 'color 0.2s',
    },
    replyActionButtonHover: {
      color: '#374151',
    },
    sendButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      padding: '0.5rem 1rem',
      backgroundColor: '#1E40AF',
      color: '#ffffff',
      borderRadius: '0.5rem',
      transition: 'background-color 0.2s',
      border:"none",
    },
    sendButtonHover: {
      backgroundColor: '#1E3A8A',
    },
    sendButtonDisabled: {
      backgroundColor: '#D1D5DB',
      cursor: 'not-allowed',
    },
  };
  
  return (
    <div className="min-h-screen bg-gray-100"  style={{position:"absolute", right:"200px", top:"30px"}} >
  

      {sidebarOpen && (
        <div style={styles.overlay}>
          <div style={styles.sidebar}>
            <div style={styles.header}>
              <h2 style={styles.headerTitle}>Notifications & Chat</h2>
              <button onClick={() => setSidebarOpen(false)} style={styles.closeButton}>
                <X style={{ width: '24px', height: '24px',  }}/>
              </button>
            </div>

            <div style={styles.filterContainer}>
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                style={styles.filterSelect}
              >
                <option value="">All</option>
                <option value="notifications">Notifications</option>
                <option value="messages">Messages</option>
              </select>
            </div>

            <div style={styles.contentContainer}>
              {(filter === '' || filter === 'notifications') && (
                <div style={styles.notificationList}>
                  <h3 style={styles.sectionTitle}>Notifications</h3>
                  <div className="space-y-3">
                    {MOCK_NOTIFICATIONS.map((notification) => (
                      <div
                        key={notification.id}
                        style={styles.notificationItem}
                      >
                        <div className="relative">
                          <Bell style={styles.bellIcon} />
                          <div style={styles.notificationDot} />
                        </div>
                        <p style={styles.notificationText}>{notification.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {(filter === '' || filter === 'messages') && (
                <div>
                  <h3 style={styles.sectionTitle}>Messages</h3>
                  <input
                    type="text"
                    placeholder="Search messages"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  style={styles.searchInput}
                  />
                  <div>
                    {filteredMessages.map((message) => (
                      <div
                        key={message.id}
                        onClick={() => setSelectedMessage(message)}
                       style={styles.messageItem} 
                       
                       
                       onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.messageItemHover.backgroundColor}
                       onMouseLeave={(e) => e.currentTarget.style.backgroundColor = styles.messageItem.backgroundColor}
                       >
                        <div style={styles.messageAvatar}>
                          {message.sender[0]}
                        </div>
                        <div className="flex-1">
                          <p style={styles.messageSender}>{message.sender}</p>
                          <p style={styles.messageText}  >{message.text}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

{selectedMessage && (
        <div
          style={{
            ...styles.fixedContainer,
            width: isMaximized ? '50%' : '400px',
            height: isMaximized ? '50%' : '',
          }}
        >
          {/* Header */}
          <div style={styles.header}>
            <div style={styles.headerTitle}>
              <div style={styles.messageAvatar}>
                {selectedMessage.sender[0]}
              </div>
              <span>{selectedMessage.sender}</span>
            </div>
            <div style={styles.headerTitle}>
              <button
                onClick={() => setIsMaximized(!isMaximized)}
                style={styles.headerButton}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.headerButtonHover.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
              >
                {isMaximized ? <ChevronDown style={{ width: '20px', height: '20px' }} /> : <ChevronUp style={{ width: '20px', height: '20px' }} />}
              </button>
              <button
                onClick={() => setSelectedMessage(null)}
                style={styles.headerButton}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.headerButtonHover.backgroundColor}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
              >
                <X style={{ width: '20px', height: '20px' }} />
              </button>
            </div>
          </div>

          {/* Message Content */}
          <div style={styles.messageContent}>
            <div style={styles.messageItem}>
              <div style={styles.messageAvatar}>
                {selectedMessage.sender[0]}
              </div>
              <div style={{ flex: 1 }}>
                <p style={styles.messageSender}>{selectedMessage.sender}</p>
                <p style={styles.messageText}>{selectedMessage.text}</p>
                {selectedMessage.media && (
                  <div style={styles.mediaContainer}>
                    {selectedMessage.media.type === 'audio' && (
                      <audio controls src={selectedMessage.media.url} style={{ width: '100%' , borderRadius:"50%"}} />
                    )}
                    {selectedMessage.media.type === 'video' && (
                      <video controls src={selectedMessage.media.url} style={{ width: '100%', borderRadius: '0.5rem',  }} />
                    )}
                    {selectedMessage.media.type === 'document' && (
                      <a
                        href={selectedMessage.media.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={styles.mediaLink}
                        onMouseEnter={(e) => e.currentTarget.style.color = styles.mediaLinkHover.color}
                        onMouseLeave={(e) => e.currentTarget.style.color = styles.mediaLink.color}
                      >
                        View Document
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Reply Box */}
          <div style={styles.replyBox}>
            <textarea
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              placeholder="Write your reply..."
              style={styles.replyTextarea}
              onFocus={(e) => e.currentTarget.style.borderColor = styles.replyTextareaFocus.borderColor}
              onBlur={(e) => e.currentTarget.style.borderColor = styles.replyTextarea.borderColor}
            />
            
            {error && (
              <div style={styles.errorMessage}>
                {error}
              </div>
            )}

            {isRecording && (
              <div style={styles.recordingContainer}>
                {recordingType === 'video' && (
                  <video
                    ref={videoPreview}
                    autoPlay
                    muted
                    playsInline
                    style={{ width: '100%', borderRadius: '0.5rem', marginBottom: '0.5rem' }}
                  />
                )}
                <div style={styles.recordingIndicator}>
                  <div style={styles.recordingDot} />
                  <span style={styles.recordingText}>Recording {formatDuration(duration)}</span>
                </div>
                <div style={styles.recordingButtons}>
                  <button
                    onClick={stopRecording}
                    style={styles.recordingButton}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.recordingButtonHover.backgroundColor}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    <Square style={{ width: '20px', height: '20px', color: '#DC2626' }} />
                  </button>
                  <button
                    onClick={() => {
                      stopRecording();
                      setReplyMedia(null);
                    }}
                    style={styles.recordingButton}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.recordingButtonHover.backgroundColor}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    <Trash2 style={{ width: '20px', height: '20px', color: '#DC2626' }} />
                  </button>
                </div>
              </div>
            )}

            {replyMedia && !isRecording && (
              <div style={styles.mediaPreview}>
                {replyMedia.type === 'audio' && (
                  <audio controls src={replyMedia.url} style={{ width: '100%' }} />
                )}
                {replyMedia.type === 'video' && (
                  <video 
                    controls 
                    src={replyMedia.url} 
                    style={{ width: '100%', borderRadius: '0.5rem' }}
                  />
                )}
                {replyMedia.type === 'document' && (
                  <a 
                    href={replyMedia.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={styles.mediaLink}
                    onMouseEnter={(e) => e.currentTarget.style.color = styles.mediaLinkHover.color}
                    onMouseLeave={(e) => e.currentTarget.style.color = styles.mediaLink.color}
                  >
                    View Document
                  </a>
                )}
                <button
                  onClick={() => setReplyMedia(null)}
                  style={styles.removeMediaButton}
                  onMouseEnter={(e) => e.currentTarget.style.color = styles.removeMediaButtonHover.color}
                  onMouseLeave={(e) => e.currentTarget.style.color = styles.removeMediaButton.color}
                >
                  Remove media
                </button>
              </div>
            )}

            <div style={styles.replyActions}>
              <div style={styles.replyActions}>
                <label style={styles.replyActionButton}>
                  <Paperclip style={{ width: '20px', height: '20px' }} />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    accept="audio/*,video/*,application/pdf"
                  />
                </label>
                
                {!isRecording && (
                  <>
                    <button
                      onClick={() => startRecording('audio')}
                      style={styles.replyActionButton}
                      onMouseEnter={(e) => e.currentTarget.style.color = styles.replyActionButtonHover.color}
                      onMouseLeave={(e) => e.currentTarget.style.color = styles.replyActionButton.color}
                      disabled={isRecording}
                    >
                      <Mic style={{ width: '20px', height: '20px' }} />
                    </button>
                    
                    <button
                      onClick={() => startRecording('video')}
                      style={styles.replyActionButton}
                      onMouseEnter={(e) => e.currentTarget.style.color = styles.replyActionButtonHover.color}
                      onMouseLeave={(e) => e.currentTarget.style.color = styles.replyActionButton.color}
                      disabled={isRecording}
                    >
                      <Video style={{ width: '20px', height: '20px' }} />
                    </button>
                  </>
                )}
              </div>

              <button
                onClick={handleSend}
                disabled={!replyText.trim() && !replyMedia}
                style={{
                  ...styles.sendButton,
                  ...(replyText.trim() || replyMedia ? {} : styles.sendButtonDisabled),
                }}
                onMouseEnter={(e) => {
                  if (replyText.trim() || replyMedia) {
                    e.currentTarget.style.backgroundColor = styles.sendButtonHover.backgroundColor;
                  }
                }}
                onMouseLeave={(e) => {
                  if (replyText.trim() || replyMedia) {
                    e.currentTarget.style.backgroundColor = styles.sendButton.backgroundColor;
                  }
                }}
              >
                <Send style={{ width: '16px', height: '16px' }} />
                <span>Send</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;