import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";


const FeaturedBlog = ({ posts, onCardClick, textScale }) => {
  
    const screenType = useSelector((state) => state.website.screenType);
    const carouselRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [containerWidth, setContainerWidth] = useState(0);
    const controls = useAnimation();
    const t = useTranslation();
    // Duplicate posts to create an infinite loop
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const loopedPosts = [...posts, ...posts];

    // Calculate the container width
    useEffect(() => {
        if (carouselRef.current) {
            const totalWidth = carouselRef.current.scrollWidth;
            const visibleWidth = carouselRef.current.offsetWidth;
            setContainerWidth(totalWidth - visibleWidth);
        }
    }, [posts, screenType]);

    // Start horizontal scrolling animation
    useEffect(() => {
        controls.start({
            x: -containerWidth, // Scroll left until the end
            transition: {
                duration: 150, // Adjust speed
                ease: "linear",
                repeat: Infinity, // Infinite horizontal looping
            },
        });
    }, [controls, containerWidth]);

    // Styles for the carousel
    const styles = (textScale) => ({
        carousel: {
            display: "flex",
            width: "100%",
marginTop: screenType==="tab"||screenType==="tablet" ?  "4vh" :  "5vh",
            alignItem: "center",
            position: "relative",
           
          
        },
        innerCarousel: {
            display: "flex",
            willChange: "transform", // Optimized for animation
            padding:"20px",
        },
        post: {
            flex: "0 0 auto", // Ensure items don't shrink or grow
            width: screenType === "phone" || screenType === "smallPhone" ? "300px" : "400px",
            height: `${550 * textScale}px`,
            margin: "0 10px", // Space between items
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            overflow: "hidden",
            backgroundColor: "white",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
        image: {
            width: "100%",
            height: "250px",
            objectFit: "cover",
        },
        content: {
            padding: "20px",
            paddingBottom: "0px"
        },
        title: {
            fontSize: `${1.5 * textScale}rem`,
            fontWeight: "bold",
            marginBottom: "10px",
        },
        description: {
            fontSize: `${1.2 * textScale}rem`,
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: isExpanded ? "unset" : 3,
            WebkitBoxOrient: "vertical",
            margin: "0px",
        },
        categoryContainer: {
            marginTop: "20px",
            fontSize: "1.1rem",
        },
        categoryLabel: {
            fontWeight: "bold",
            color: "#333",
        },
        categoryText: {
            fontStyle: "italic",
            color: "#007BFF",
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
        },
        button: {
            padding: '10px 20px',
            margin: '0 10px',
            borderRadius: '5px',
            border: 'none',
            display: "flex",
            backgroundColor: '#007bff',
            color: '#fff',
            cursor: 'pointer',
        },
        span: {
            fontSize: "12px",
            textTransform: "uppercase",
            opacity: 0.5,
            letterSpacing: "1px",
            fontWeight: "bold",
        },
        tagsList: {
            display: "flex",
        },
        tagItem: {
            fontSize: "12px",
            letterSpacing: "2px",
            listStyle: "none",
            marginLeft: "8px",
            textTransform: "uppercase",
            position: "relative",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
        },
    });
const navigate = useNavigate()
const handlePostClick = (post) => {
  
    navigate(`/blogs/BlogDetailsScreen`, { state: { post , documentId: post.objectID}});
  };

    return (



        <div style={styles(textScale).carousel} ref={carouselRef}>
            <motion.div
                style={styles(textScale).innerCarousel}
                animate={controls}
            >
                {loopedPosts.map((post, index) => (
                    <motion.div
                        key={index}
                        style={styles(textScale).post}
                        onClick={()=>{handlePostClick(post)}}
                        animate={{
                            y: index % 2 === 0 ? -30 : 30, // Alternating up and down
                            //   transition: {
                            //     duration: 2, // Speed of the vertical movement
                            //     ease: "easeInOut",
                            //     // repeat: Infinity, // Infinite zig-zag
                            //     repeatType: "reverse", // Reverse direction at each cycle
                            //   },
                        }}
                        onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
                        onMouseLeave={() => setHoveredIndex(null)} // 
                    >
                        <img src={post.imageLink
                        } alt={post.title}

                            style={{
                                ...styles(textScale).image,
                                transform:hoveredIndex === index ? "scale(1.3)" : "scale(1)", // Scale effect
                                transition: "transform 0.3s ease-in-out", // Smooth scaling transition
                            }}
                        />
                        <div 
                                 style={{
                                    ...styles(textScale).content,
                                    // height: "60%",
                                    transform: hoveredIndex === index ? "translateY(30px)" : "translateY(0)", // Conditional transform
                                    transition: "transform 0.2s ease-in-out", // Smooth transition for the effect
                                  }}

                        >
                            <h3 style={styles(textScale).title}>{post.blogtopic
                            }</h3>
                            <p style={styles(textScale).description}>{post.blogDescription}</p>
                     

                        {!isExpanded &&
                            post.blogDescription.length > 200 && ( // Check if the content is longer
                                <div style={{    display: "flex",
                                    justifyContent: "flex-end",}}>

                                <button
                                    style={{
                                        color: "#FF6347",
                                        background: "none",
                                        border: "none",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        cursor: "pointer",
                                        opacity: isHovered ? 1 : 0.5, // Show the button only on hover
                                        transition: "opacity 0.3s ease-in-out",
                                    }}
                                    onClick={()=>{handlePostClick(post)}}
                                >
                                    See More
                                </button>
                                </div>
                            )}

                        <div style={styles(textScale).containerInfos}>
                            <div style={styles(textScale).containerTags}>
                                <span style={styles.span}>Blog Category</span>
                                <div style={styles(textScale).tagsList}>
                                    <ul>
                                        <li style={{ ...styles(textScale).tagItem, fontSize: `${1.2 * textScale}rem` }}>{post.blogcategory}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </div>

    );
};

export default FeaturedBlog;
