import React, { useState, useEffect, useCallback } from "react";

import AnimatedList from "./greetingText";
import SidebarMobile from "../../Components/studyBuddyWA/SidebarMobile";
import AnimatedBird from "../studyBuddyWA/AnimationBird";
import useScreenSizes from "../../utils/useScreenSize";
import { useDispatch, useSelector } from "react-redux";
import grassBg from "../../assets/images/grass.webp";
import {
  setHeaderHeight,
  setLoading1,
  setLoading2,
  setReach,
  setTheme,
} from "../../reduxStore/slices/WebSiteData";
import { useLocation, useNavigate } from "react-router-dom";
import themes from "../../config/theme";
import { useTranslation } from "react-i18next";

function TitleBarWebsite({ setFixed }) {
  const [scale, setScale] = useState(1);
  const handleThemeChange = (themeName) => {
    dispatch(setTheme(themeName)); // Dispatch the theme change
  };
  const [activeText, setActiveText] = useState(null);
  // const [activeBoard, setActiveBoard] = useState(null);
  const [Tab, setTab] = useState();
  const navigate = useNavigate();
  const reach = useSelector((state) => state.website.reach);
  const headerHeight = useSelector((state) => state.website.headerHeight);
  const [isPhone, setIsPhone] = useState();
  const [Phone, setPhone] = useState();
  const screen = useScreenSizes();
  const location = useLocation();
  const dispatch = useDispatch();
  const [woodenBoardState, setWoodenBoardState] = useState(null);
  const currentTheme = useSelector((state) => state.website.theme);
  const selectedTheme = themes[currentTheme] || themes.default; // Use selected theme

  const [woodenBoard, setWoodenBoard] = useState({
    Vision: { height: "110px", width: "120px" },
    Blogs: { height: "110px", width: "120px" },
    FAQ: { height: "110px", width: "120px" },
    Solution: { height: "110px", width: "120px" },
  });
 
  useEffect(() => {
    if (screen >= 1200) {
      // Desktop
      setTab(false);
      setIsPhone(false);
      setPhone(false);
      setWoodenBoard({
        Vision: { height: "110px", width: "120px" },
        Blogs: { height: "110px", width: "120px" },
        FAQ: { height: "110px", width: "120px" },
        Solution: { height: "110px", width: "120px" },
      });
    } else if (screen >= 1000) {
      // Tablet (iPad Pro portrait)
      setTab(true);
      setIsPhone(false);
      setPhone(false);
      setWoodenBoard({
        Vision: { height: "90px", width: "100px" },
        Blogs: { height: "90px", width: "100px" },
        FAQ: { height: "90px", width: "100px" },
        Solution: { height: "90px", width: "100px" },
      });
    } else if (screen >= 650 && screen>=700) {
      // Other smaller tablets
      setTab(true);
      setIsPhone(false);
      setPhone(false);
      setWoodenBoard({
        Vision: { height: "90px", width: "100px" },
        Blogs: { height: "90px", width: "100px" },
        FAQ: { height: "90px", width: "100px" },
        Solution: { height: "90px", width: "100px" },
      });
    } else if (screen >= 350 && screen < 650) {
      // Mobile
      setTab(false);
      setIsPhone(true);
      setPhone(false);
    } else {
      setPhone(true);
      setTab(false);
      setIsPhone(false);
    }
  }, [screen]);
  const [Tablet, setTablet] = useState();
  useEffect(() => {
    if (screen >= 1000 && screen < 1200) {
      setTablet(true);
      setTab(false);
    } else setTablet(false);
  });
  const handleNavigation = (path) => {
    if (location.pathname !== path) {
      navigate(path);
      dispatch(setReach(true));
    }
  };

  const getActiveBoard = () => {
    const pathname = location.pathname;
    if (pathname.includes("vision")) return "Vision";
    if (pathname.includes("blogs")) return "Blogs";
    if (pathname.includes("FAQ")) return "FAQ";
    if (pathname.includes("solution")) return "Solution";
    return null;
  };
  const loading = useSelector((state) => state.website.loading);

  const activeBoard = getActiveBoard();
  const handleMouseEnter = (board) => {
    setWoodenBoardState(board);

    // Apply different size based on Tab or non-Tab
    if (!Tab) {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "132px" : "130px", // Use 132px for active board
          width: activeBoard === board ? "132px" : "130px",
          textTop: "55px",
          transition: "0.3s all linear",
        },
      }));
    } else {
      // In Tab mode, adjust sizes
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "110px" : "90px", // Use 110px for active, 90px for non-active
          width: activeBoard === board ? "120px" : "100px",
          textTop: activeBoard === board ? "56px" : "49px", // Adjust text top
        },
      }));
    }
  };

  const handleMouseLeave = (board) => {
    setWoodenBoardState(null);

    // Apply different size based on Tab or non-Tab
    if (!Tab) {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "132px" : "110px", // Keep the increased size if active
          width: activeBoard === board ? "132px" : "120px",
          textTop: activeBoard === board ? "55px" : "49px",
        },
      }));
    } else {
      // In Tab mode, adjust sizes
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "110px" : "90px", // Use 110px for active, 90px for non-active
          width: activeBoard === board ? "120px" : "100px",
          textTop: activeBoard === board ? "56px" : "49px", // Adjust text top
        },
      }));
    }
  };

  // Update sizes based on active state and screen mode
  useEffect(() => {
    if (!isPhone && !Tab) {
      setWoodenBoard((prevSizes) => {
        const newSizes = { ...prevSizes };
        Object.keys(newSizes).forEach((key) => {
          if (key === activeBoard) {
            newSizes[key] = {
              height: "132px",
              width: "132px",
              textTop: "55px",
            };
          } else {
            newSizes[key] = {
              height: "110px",
              width: "120px",
              textTop: "49px",
            };
          }
        });
        return newSizes;
      });
    } else if (Tab) {
      setWoodenBoard((prevSizes) => {
        const newSizes = { ...prevSizes };
        Object.keys(newSizes).forEach((key) => {
          if (key === activeBoard) {
            newSizes[key] = {
              height: "110px",
              width: "120px",
              textTop: "56px",
            }; // Tab active state
          } else {
            newSizes[key] = { height: "90px", width: "100px", textTop: "49px" }; // Tab non-active state
          }
        });
        return newSizes;
      });
    }
  }, [activeBoard, isPhone, Phone, Tab]);

  const styles = {
    header: {
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    
      height: "100%",
      display: "flex",
      zIndex: 1,
    },
    treeButton: {
      position: "absolute",
      bottom: 0,
      height:
        isPhone || Phone
          ? reach
            ? "9vh"
            : "24vh"
          : Tab
          ? reach
            ? "14vw"
            : "35vh"
          : Tablet
          ? reach
            ? "9vw"
            : "35vh"
          : reach
          ? "17vh"
          : "88vh",
      transition: "height 0.4s ease",
      cursor: "pointer",
      zIndex: 10,
    },

    wordmarkHeight: {
      height: reach ? "180px" : "",
    },
    grassContainer: {
      position: "absolute",
      width: "100vw",
      height:
        isPhone || Phone
          ? reach
            ? "6px"
            : "17px"
          : reach
          ? "11px"
          : Tab
          ? reach
            ? "10px"
            : "20px"
          : `${30 * scale}px`,
      bottom: isPhone || Phone ? (reach ? "0px" : "-3px") : "0px",
      backgroundImage: `url(${selectedTheme.Grass})`,
      backgroundSize: "contain",
      backgroundRepeatX: "repeat",
      zIndex: 200,
      transition: "height 0.3s ease-out",
    },
    animatedListContainer: {
      position: "absolute",
      borderRadius: "20px",
      padding: Phone?  "": "20px",
      top:
        isPhone || Phone
          ? "10%"
          : Tab
          ? " 72%"
          :  Tab  ? "60%" : Tablet ? "73%"  : screen > 1200
          ? "60%"
          : "50%",
      right: isPhone || Phone ? "3%" : Tab ? "3%" : "3%",
      display: reach ? "none" : "",
      zIndex: 3,
      opacity: reach ? 0 : 1,
      transition: "opacity 0.2s ease-out",
    },
    rockContainer: {
      backgroundColor: "transparent",
      position: "absolute",
      right: 0,
      bottom: -6,
      opacity: reach ? 1 : 0,
      right: reach ? "10px" : "-200px",
      transition: "right 1s ease-out",
      cursor: "pointer",
      zIndex: 10,
    },
    wordMarks: {
      position: "absolute",
      height:   Phone
        ? reach
          ? "40px"
          : "9vw"
        : isPhone
        ? reach
          ? "40px"
          : "10vw"
          :
          Tab ?    reach
          ? "30px"
          : "90px"
        : Tablet
        ? reach
          ? "50px"
          : "130px"
        : reach
        ? "5vw"
        : "9vw",

      zIndex: 0,
      cursor: "pointer",
      left: screen >= 650 && screen <=800 ? reach  ?  "46%": "55%"
        : isPhone
        ? reach
          ? "38%"
          : "62%"
          : Phone
          ? reach
            ? "38%"
            : "60%"
        : Tablet
        ? reach
          ? "42%"
          : "55%"
        : Tab
        ? reach
          ? "47%"
          : "60%"
        : reach
        ? "42%"
        : "60%",
      top: screen >= 900 && screen <=1000  ? reach ?  "28%":"40%" : 
          screen >= 700 && screen <= 800
      ? reach
        ? "55%"
        : "45%"
      
      : 
      
      
      isPhone
        ? reach
          ? "25%"
          : "58%"
        : Phone
        ? reach
          ? "25%"
          : "58%":
        Tablet
          ? reach
            ? "28%"
            : "33%"
        :  Tab ?   reach ?
         "50%"
        : "33%" :  reach
        ? "18%"
        : "20%",
      transition: "height 0.5s ease-out, left 0.5s ease-out, top 0.5s ease-out",
    },
    Vision: {
      position: "absolute",
      left: Tab ? "20%" : "15%",
      top: reach ? "0px" : "-80px",
      transition: "height 3s ease-in-out, top 1s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextVision: {
      fontFamily: "lemonfont",
      position: "absolute",
      left: activeBoard === "Vision" ? "22%" : "18%",
      fontSize: Tab ? "11px" : "14px",
      top:
        woodenBoardState === "Vision" || activeBoard === "Vision"
          ? Tab
            ? "59px" // Set top to 56px if active and on a tab
            : "68px" // Default to 68px for non-tab devices
          : reach
          ? Tab
            ? "45px" // When not active but tab, use 45px
            : "56px" // When not active and not tab, use 56px
          : "-80px", // Off-screen when not reached
      transition: "top s ease-in-out",
      opacity: reach ? 1 : 0,

      color: "white",
    },
    contact: {
      position: "absolute",
      right: "50%",
      top: reach ? "0px" : "-80px",
      transition: "top 1s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextContact: {
      fontFamily: "lemonfont",
      position: "absolute",
      right: "15%",
      fontSize: Tab ? "small" : "",
      top:
        woodenBoardState === "contact" || activeBoard == "contact"
          ? Tab
            ? "56px" // Set top to 56px if active and on a tab
            : "68px"
          : reach
          ? Tab
            ? "45px"
            : "56px"
          : "-80px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    Blogs: {
      position: "absolute",
      right: "30%",
      top: reach ? "0px" : "-80px",
      transition: "top 1s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextBlogs: {
      fontFamily: "lemonfont",
      position: "absolute",
      right: activeBoard == "Blogs" ? "28%" : "28%",
      fontSize: Tab ? "small" : "",
      top:
        woodenBoardState === "Blogs" || activeBoard == "Blogs"
          ? Tab
            ? "56px" // Set top to 56px if active and on a tab
            : "68px"
          : reach
          ? Tab
            ? "45px"
            : "56px"
          : "-80px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    FAQ: {
      position: "absolute",
      right: "16%",
      top: reach ? "0px" : "-80px",
      transition: "top 1s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextFAQ: {
      fontFamily: "lemonfont",
      position: "absolute",
      right: "31%",
      fontSize: Tab ? "small" : "",
      top:
        woodenBoardState === "FAQ" || activeBoard == "FAQ"
          ? Tab
            ? "56px" // Set top to 56px if active and on a tab
            : "68px"
          : reach
          ? Tab
            ? "45px"
            : "56px"
          : "-80px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    Solution: {
      position: "absolute",

      left: Tab ? "35%" : "27%",
      top: reach ? "0px" : "-80px",
      transition: "top 1s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextSolution: {
      fontFamily: "lemonfont",
      position: "absolute",
      fontSize: Tab ? "small" : "",
      right: activeBoard == "Solution" ? "13%" : "13%",
      top:
        woodenBoardState === "Solution" || activeBoard == "Solution"
          ? Tab
            ? "56px" // Set top to 56px if active and on a tab
            : "68px"
          : reach
          ? Tab
            ? "45px"
            : "56px"
          : "-80px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
      // zIndex:1000,
    },

    loginLink: {
      position: "absolute",
      color: "black",
      top: -22,

      fontFamily: "lemonfont",
      opacity: reach ? 1 : 0,
      right: reach ? "20px" : "-200px",
      transition: "right 1s ease-out",
      cursor: "pointer",
    },
  };
 const {t} = useTranslation();
 const height = window.innerHeight
 const width = window.innerWidth
 console.log('height: ', height , width);
  return (
    <div>
      <div style={styles.header}>
        <div style={styles.treeButton}>
          <img
            src={selectedTheme.logo}
            style={styles.treeButton}
            onLoad={() => dispatch(setLoading1(false))}
            onError={() => {
              dispatch(setLoading1(false));
              console.log(setLoading1);
            }}
            onClick={() => handleNavigation("/")}
          />
          <div style={styles.grassContainer}></div>
          <div>
            <AnimatedBird />
          </div>
        </div>
        {!(isPhone || Phone) ? (
          <>
            <div
              style={styles.rockContainer}
              onClick={() => handleNavigation("/Login")}
            >
              <img
                src={require("../../assets/images/stone-cave-with-liana.webp")}
                style={{ height: "70px" }}
                alt="Rock"
              />
              <div style={styles.loginLink}>{t("Login")}</div>
            </div>
  
            <div
              style={styles.Vision}
              {...(!Tab && {
                onMouseEnter: () => handleMouseEnter("Vision"),
                onMouseLeave: () => handleMouseLeave("Vision"),
              })}
              onClick={() => handleNavigation("/vision")}
            >
              <img
                src={require("../../assets/images/Wooden-Board.webp")}
                style={{
                  height: woodenBoard.Vision.height,
                  width: woodenBoard.Vision.width,
                }}
                alt="Vision"
              />
              <div style={styles.boardTextVision}>{t("Our Vision")}</div>
            </div>
  
            <div
              style={styles.Blogs}
              {...(!Tab && {
                onMouseEnter: () => handleMouseEnter("Blogs"),
                onMouseLeave: () => handleMouseLeave("Blogs"),
              })}
              onClick={() => handleNavigation("/blogs")}
            >
              <img
                src={require("../../assets/images/Wooden-Board.webp")}
                style={{
                  height: woodenBoard.Blogs.height,
                  width: woodenBoard.Blogs.width,
                }}
                alt="Blogs"
              />
              <div style={styles.boardTextBlogs}>{t("Blogs")}</div>
            </div>
            <div
              style={styles.FAQ}
              {...(!Tab && {
                onMouseEnter: () => handleMouseEnter("FAQ"),
                onMouseLeave: () => handleMouseLeave("FAQ"),
              })}
              onClick={() => handleNavigation("/FAQ")}
            >
              <img
                src={require("../../assets/images/Wooden-Board.webp")}
                style={{
                  height: woodenBoard.FAQ.height,
                  width: woodenBoard.FAQ.width,
                }}
                alt="FAQ"
              />
            
              <div style={styles.boardTextFAQ}>{t("FAQs")}</div>
            </div>
            <div
              style={styles.Solution}
              {...(!Tab && {
                onMouseEnter: () => handleMouseEnter("Solution"),
                onMouseLeave: () => handleMouseLeave("Solution"),
              })}
              onClick={() => handleNavigation("/solution")}
            >
              <img
                src={require("../../assets/images/Wooden-Board.webp")}
                style={{
                  height: woodenBoard.Solution.height,
                  width: woodenBoard.Solution.width,
                }}
                alt="Solution"
              />
              <div style={styles.boardTextSolution}>{t("Solutions")}</div>
            </div>
          </>
        ) : headerHeight == "50vh " ? null : (
          <SidebarMobile />
        )}
  
        <div style={styles.animatedListContainer}>
    
          <AnimatedList
            items={[
              "नमस्ते", // मराठी
              " ਸਤ ਸ੍ਰੀ ਅਕਾਲ", // पंजाबी
              "নমস্তে", // बांग्ला
              "નમસ્તે", // गुजराती
              "வணக்கம்", // तमिल
              "నమస్కారం", // तेलुगू
              "ನಮಸ್ಕಾರ", // कन्नड़
              "നമസ്കാരം", // मलयालम
              "ନମସ୍କାର", // उड़ीया
              "নমস্কাৰ", // असमिया
              "नमस्कार", // कोंकणी
              "سلام", // सिंधी
              "राम राम", // राजस्थानी
              "नमस्ते", // बोडो
              "ᱵᱟᱲᱟᱲ", // संथाली
              "آداب", // कश्मीरी
            ]}
            
          />
        </div>
        <div style={{ width: "50%", zIndex: 10 }}>
          <div style={styles.wordMarks}>
            <img
              src= {selectedTheme.wordMark}
              style={styles.wordMarks}
              alt="Wordmark"
              onLoad={() =>  dispatch(setLoading2(false))} // Set loading to false when image loads
              onError={() => {
                dispatch(setLoading2(false));
                console.error("Failed to load image.");
              }}
            />

          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleBarWebsite;
