import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useScreenSizes from "../../utils/useScreenSize";
import AnimatedBird from "../studyBuddyWA/AnimationBird";
import { useSelector, useDispatch } from "react-redux";
import { setAnimate, setTreeClick } from "../../reduxStore/slices/WebSiteData";
import SidebarMobile from "./SidebarMobile";
import NotificationChatSidebar from "../test/ChatSideBar";

const TitleBar = () => {
  const screen = useScreenSizes();
  const dispatch = useDispatch();
  const [height, setHeight] = useState("small");
  const [reach, setReach] = useState(true);
  const location = useLocation();
  const screenType = useSelector((state) => state.website.screenType);
  const TitlebarHeight = useSelector((state) => state.website.TitlebarHeight);
  const animate = useSelector((state) => state.website.animate);
  const treeClick = useSelector((state) => state.website.treeClick);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [woodenBoardState, setWoodenBoardState] = useState(null);
  const currentTheme = useSelector((state) => state.website.theme);
  const [ expandTitlebar , setExpandTitlebar] = useState(false) ;
  const [woodenBoard, setWoodenBoard] = useState({
    Vision: { height: "90px", width: "100px" },
    Blogs: { height: "90px", width: "100px" },
    FAQ: { height: "90px", width: "100px" },
    Solution: { height: "90px", width: "100px" },
  });

  useEffect(() => {
    if (screen === "desktop") {
      setWoodenBoard({
        Vision: { height: "90px", width: "100px" },
        Blogs: { height: "90px", width: "100px" },
        FAQ: { height: "90px", width: "100px" },
        Solution: { height: "90px", width: "100px" },
      });
    } else if (screenType === "tablet") {
      setWoodenBoard({
        Vision: { height: "90px", width: "100px" },
        Blogs: { height: "90px", width: "100px" },
        FAQ: { height: "90px", width: "100px" },
        Solution: { height: "90px", width: "100px" },
      });
    } else if (screenType === "tab") {
      setWoodenBoard({
        Vision: { height: "90px", width: "100px" },
        Blogs: { height: "90px", width: "100px" },
        FAQ: { height: "90px", width: "100px" },
        Solution: { height: "90px", width: "100px" },
      });
    }
  }, [screen, screenType]);

  const getActiveBoard = () => {
    const pathname = location.pathname;
    if (pathname.includes("vision")) return "Vision";
    if (pathname.includes("blogs")) return "Blogs";
    if (pathname.includes("FAQ")) return "FAQ";
    if (pathname.includes("solution")) return "Solution";
    return null;
  };

  useEffect(() => {
    if (animate) {
      setTimeout(() => dispatch(setAnimate(false)), 2000);
    }
  }, [animate]);

  const handleTreeButtonClick = () => {
    dispatch(setTreeClick(!treeClick));
  };

  const activeBoard = getActiveBoard();

  const handleMouseEnter = (board) => {
    setWoodenBoardState(board);
  
    if (screenType !== "tab") {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "110px" : "110px",
          width: activeBoard === board ? "110px" : "110px",
          textTop: "55px",
          transition: "0.3s all linear",
        },
      }));
    } else {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "110px" : "90px",
          width: activeBoard === board ? "120px" : "100px",
          textTop: activeBoard === board ? "46px" : "49px",
          transition: "0.3s all linear", // Add transition for smooth animation
        },
      }));
    }
  };
  
  const handleNotificationClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleMouseLeave = (board) => {
    setWoodenBoardState(null);
  
    if (screenType !== "tab") {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "110px" : "90px",
          width: activeBoard === board ? "110px" : "100px",
          textTop: activeBoard === board ? "55px" : "49px",
        },
      }));
    } else {
      setWoodenBoard((prevSizes) => ({
        ...prevSizes,
        [board]: {
          ...prevSizes[board],
          height: activeBoard === board ? "110px" : "90px",
          width: activeBoard === board ? "120px" : "100px",
          textTop: activeBoard === board ? "46px" : "49px",
        },
      }));
    }
  }; 


  useEffect (()=>{
    if(TitlebarHeight==="100dvh"){
      setExpandTitlebar(true)
    }
    else{ 
      setExpandTitlebar(false)
    }
   },[TitlebarHeight])

  const handleNavigation = (path) => {
  
  };

  const styles = {
    container: {
      margin: 0,
      padding: 0,
      // height: TitlebarHeight,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      zIndex: 100,
    },
    header: {
      backgroundImage: `url(${require("../../assets/images/background.webp")})`,
      display: "grid",
      alignItems: "end",
      width: "100%",
      backgroundPosition: "top",
      height:TitlebarHeight,
      backgroundRepeat: "repeat-x",

      transition: "height 0.5s ease-in-out",
      position: "absolute",
      overflow: "hidden",
    },
    GrassContainer: {
      position: "absolute",
      display: "flex",
      width: "100vw",
      height: screenType === "smallPhone" || screenType === "phone" ? "10px" : "10px",
      flexDirection: "row",
      backgroundImage: `url(${require("../../assets/images/grass.webp")})`,
      backgroundSize: "contain",
      backgroundRepeat: "repeat-x",
      zIndex: 2,
    },
    TreeButton: {
      cursor: "pointer",
      position: "absolute",
      zIndex: 1,
      bottom: "-3px",
      width: screenType === "phone" || screenType === "smallPhone" ? "10vh" : screenType==="tab"||screenType==="tablet"? "13vh" : "16vh",
    },
    wordmark: {
      position: "absolute",
      zIndex: 0,
      cursor: "pointer",
      left: screenType === "phone" ? "41%" : screenType === "smallPhone" ? "41%" : screenType === "tablet" ? "40%" : screenType === "tab" ? "40%" : "41%",
      top: screen >= 500 && screen <= 650 ? "29%" : screenType === "smallPhone" ? "53%" : screenType === "phone" ? "48%" : screenType === "tab" ? "29%" : screenType === "tablet" ? "30%" : "25%",
      transition: "height 0.5s ease-out, left 0.5s ease-out, top 0.5s ease-out",
      zIndex: 100,
    },
    Vision: {
      position: "absolute",
      left: screenType === "tab" ? "20%" : "20%",
      top: TitlebarHeight==="10vh"||TitlebarHeight==="6vh" ?  "-30px": "0px" ,
      transition: "height 3s ease-in-out, top 0.5s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextVision: {
      fontFamily: "lemonfont",
      position: "absolute",
      left: activeBoard === "Vision" ? "22%" : "13%",
      fontSize: screenType === "tab" ? "11px" : "small",
      top: woodenBoardState === "Vision" || activeBoard === "Vision" ? screenType === "tab" ? "59px" : "57px" : reach ? screenType === "tab" ? "45px" : "47px" : "0px",
      transition: "top s ease-in-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
   
    Blogs: {
      position: "absolute",
      right: "30%",
      top: TitlebarHeight==="10vh"||TitlebarHeight==="6vh" ?  "-30px": "0px",
      transition: "top 0.5s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextBlogs: {
      fontFamily: "lemonfont",
      position: "absolute",
      right: activeBoard === "Blogs" ? "28%" : "28%",
      fontSize: screenType === "tab" ? "small" : "small",
      top: woodenBoardState === "Blogs" || activeBoard === "Blogs" ? screenType === "tab" ? "46px" : "56px" : reach ? screenType === "tab" ? "45px" : "46px" : "0px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    FAQ: {
      position: "absolute",
      right:  screenType==="tab" ? "8%" : screenType==="tablet"  ? "8%":"8%",
      top: TitlebarHeight==="10vh"||TitlebarHeight==="6vh" ?  "-30px": "0px",
      transition: "top 0.5s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextFAQ: {
      fontFamily: "lemonfont",
      position: "absolute",
      right: "35%",
      fontSize: screenType === "tab" ? "small" : "small",
      top: woodenBoardState === "FAQ" || activeBoard === "FAQ" ? screenType === "tab" ? "46px" : "56px" : reach ? screenType === "tab" ? "45px" : "46px" : "0px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    Solution: {
      position: "absolute",
      left: screenType === "tab"||screenType==="tablet" ? "40%" : "40%",
      top: TitlebarHeight==="10vh"||TitlebarHeight==="6vh" ?  "-30px": "0px",
      transition: "top 0.5s ease-out",
      opacity: reach ? 1 : 0,
      cursor: "pointer",
      zIndex: 10,
    },
    boardTextSolution: {
      fontFamily: "lemonfont",
      position: "absolute",
      fontSize: screenType === "tab" ? "small" : "small",
      right: activeBoard === "Solution" ? "13%" : "13%",
      top: woodenBoardState === "Solution" || activeBoard === "Solution" ? screenType === "tab" ? "46px" : "56px" : reach ? screenType === "tab" ? "45px" : "46px" : "0px",
      transition: "top 0s ease-out",
      opacity: reach ? 1 : 0,
      color: "white",
    },
    rockContainer: {
      backgroundColor: "transparent",
      position: "absolute",
      right: 0,
      bottom: -2,
      opacity: reach ? 1 : 0,
      right: reach ? "10px" : "-200px",
      transition: "right 1s ease-out",
      cursor: "pointer",
      zIndex: 10,
    },
  };

  return (
    <div>
      <div style={styles.container}>
        <AnimatedBird />
        <div style={styles.header}> 

        
       
          <div>
             { screenType==="desktop" &&TitlebarHeight === "10vh"||(screenType==="tab"||screenType==="tablet"||screenType==="phone"||screenType==="smallPhone")&& TitlebarHeight==="6vh" ?   (
              <div>
                <img
                  src={require("../../assets/images/wordmark.webp")}
                  style={{
                    height: screen >= 500 && screen <= 700 ? "5vw" : screenType === "phone" ? "25px" : screenType === "smallPhone" ? "20px" : screenType==="tab" ? "5vw" :screenType === "tablet" ? "5vw" : "50px",
                  }}
                />
              </div>
            ) : (
              <div style={styles.TreeButton} onClick={handleTreeButtonClick}>
                <img src={require("../../assets/images/logo.webp")} style={{ width: "100%", marginLeft: 10 }} />
              </div>
            )}
          </div>
          <div style={styles.GrassContainer}></div> 
           { !expandTitlebar ? (
          <>

<div style={styles.rockContainer} onClick={()=>handleNotificationClick()}>
            <img src={require("../../assets/images/notification.webp")} style={{height:TitlebarHeight==="10vh" ||TitlebarHeight==="6vh"? "40px":"70px", width:"auto" , transition:"height 0.5s ease-out"}} />
          </div>
          {!(screenType === "smallPhone" || screenType === "phone") ? (
            <>
              <div
                style={styles.Vision}
                {...(screenType !== "tab" && {
                  onMouseEnter: () => handleMouseEnter("Vision"),
                  onMouseLeave: () => handleMouseLeave("Vision"),
                })}
                onClick={() => handleNavigation("/vision")}
              >
                <img
                  src={require("../../assets/images/Wooden-Board.webp")}
                  style={{
                    height: woodenBoard.Vision.height,
                    width: woodenBoard.Vision.width,
                  }}
                  alt="Vision"
                />
                <div style={styles.boardTextVision}>{" Our Vision"}</div>
              </div>

              <div
                style={styles.Blogs}
                {...(screenType !== "tab" && {
                  onMouseEnter: () => handleMouseEnter("Blogs"),
                  onMouseLeave: () => handleMouseLeave("Blogs"),
                })}
                onClick={() => handleNavigation("/blogs")}
              >
                <img
                  src={require("../../assets/images/Wooden-Board.webp")}
                  style={{
                    height: woodenBoard.Blogs.height,
                    width: woodenBoard.Blogs.width,
                  }}
                  alt="Blogs"
                />
                <div style={styles.boardTextBlogs}>{"Blogs"}</div>
              </div>
              <div
                style={styles.FAQ}
                {...(screenType !== "tab" && {
                  onMouseEnter: () => handleMouseEnter("FAQ"),
                  onMouseLeave: () => handleMouseLeave("FAQ"),
                })}
                onClick={() => handleNavigation("/FAQ")}
              >
                <img
                  src={require("../../assets/images/Wooden-Board.webp")}
                  style={{
                    height: woodenBoard.FAQ.height,
                    width: woodenBoard.FAQ.width,
                  }}
                  alt="Blogs"
                />
                <div style={styles.boardTextFAQ}>{"FAQ"}</div>
              </div>
              <div
                style={styles.Solution}
                {...(screenType !== "tab" && {
                  onMouseEnter: () => handleMouseEnter("Solution"),
                  onMouseLeave: () => handleMouseLeave("Solution"),
                })}
                onClick={() => handleNavigation("/solution")}
              >
                <img
                  src={require("../../assets/images/Wooden-Board.webp")}
                  style={{
                    height: woodenBoard.Solution.height,
                    width: woodenBoard.Solution.width,
                  }}
                  alt="Solution"
                />
                <div style={styles.boardTextSolution}>{"Solutions"}</div>
              </div>
            </>

          ) : (
            <div style={{zIndex:100}}>
              <SidebarMobile />
              </div>
          )}
  {isSidebarOpen && <NotificationChatSidebar open={isSidebarOpen} onClose={handleNotificationClick} />}          
  </>):null }
        </div>
      </div>
    </div>
  );
};

export default TitleBar;