import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import { addDoc, arrayUnion, collection, count, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import backgroundImage from '../../assets/images/events-1-min.png';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../Alert';
import { functions } from "../../firebase"; // Import initialized functions
import { httpsCallable } from "firebase/functions";
import { height } from '@mui/system';
import ReusableDropdown from './ReusableDropdown';
import ThemeDropdown from "../ResuableThemeComponent/ThemeDropdown"
import { clearOtp, deleteFormData, storeFormData, storeOtp } from '../../reduxStore/slices/FestForm';
import { User } from 'lucide-react';

function FestInputForm() {
  const sendEventEmail = httpsCallable(functions, "sendEventEmail");
  const sendOTPMessage = httpsCallable(functions, "sendOTPMessage")
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [gmail, setGmail] = useState('');

  const storedOtp = useSelector((state) => state.form.otp);
  const otpTimestamp = useSelector((state) => state.form.otpTimestamp);
  const [selectedButton, setSelectedButton] = useState("student");
  const [otp, setOtp] = useState(""); // User-entered OTP
  const [generatedOtp, setGeneratedOtp] = useState(null); // OTP sent to the user
  const [isVerified, setIsVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(60); // Timer for 60 seconds
  const [role, setRole] = useState("");
  const [otpTimer, setOtpTimer] = useState(300);
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const storedFormData = useSelector((state) => state.form.formData);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  useEffect(() => {
    if (storedOtp && otpTimestamp) {
      const interval = setInterval(() => {
        const timeElapsed = Math.floor((Date.now() - otpTimestamp) / 1000);
        const timeRemaining = 300 - timeElapsed;
        setOtpTimer(timeRemaining);

        if (timeRemaining <= 0) {
          dispatch(clearOtp());
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [storedOtp, otpTimestamp, dispatch]);
  useEffect(() => {
    let countdown = formData.timer || timer;
    let interval;

    if (isOtpSent) {
      interval = setInterval(() => {
        countdown -= 1;
        setTimer(countdown);
        if (countdown <= 0) {
          clearInterval(interval);
          setIsResendDisabled(false); // Enable "Resend OTP" after 60 seconds
        }
      }, 1000);
    }

    // Cleanup function to clear the interval when the component unmounts or when isOtpSent changes
    return () => clearInterval(interval);
  }, [isOtpSent]);
  useEffect(() => {
    // Fetch roles from the specific Firestore document
    const fetchRoles = async () => {
      try {
        // Reference to the specific document
        const docRef = doc(firestore, "Schools", "0OKaRxOPQSbtH44aDMxd"); // Document ID here

        const docSnap = await getDoc(docRef); // Use getDoc to fetch the document

        if (docSnap.exists()) {
          const data = docSnap.data();


          if (data && data.schoolnames) {
            // Assuming roles are stored as an array of strings in Firestore
            const roleOptions = data.schoolnames.map((role) => ({
              label: role,
              value: role,
            }));
            setRoles(roleOptions);

          } else {
            console.log("No roles found in Firestore document.");
          }
        } else {
          console.log("No such document found!");
        }
      } catch (error) {
        console.error("Error fetching roles: ", error);
      }
    };

    fetchRoles();
  }, []); // Empty dependency array means this effect runs only once after the initial render.

  const handleSelectEvent = (selectedOption) => {
    if (!formData.classLevel) {
      alert("Please select a class first.");
      return;
    }

    // Update form data with the selected event
    const updatedFormData = {
      ...formData,
      event: selectedOption
    };
    dispatch(storeFormData(updatedFormData))
    setFormData(updatedFormData);
  };



  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    school: '',
    location: '',
    mobile: '',
    email: '',
    school: '',
    additionalInfo: '',
    classLevel: '',
    event: '',
    otp: '',

  });
  useEffect(() => {
    if (Object.keys(storedFormData).length > 0) {
      if (storedFormData.otp) {
        setIsOtpSent(true)
      }
      setFormData(storedFormData);
    }
  }, [storedFormData]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile' && !/^[0-9]*$/.test(value)) {
      return; // Prevent non-numeric input
    }

    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    if (name === 'classLevel') {
      updatedFormData.event = '';  // Reset selected event
    }

    setFormData(updatedFormData);
    dispatch(storeFormData(updatedFormData))
  };

  const screenType = useSelector((state) => state.website.screenType);
  useEffect(() => {
    const requiredFields = ['name', 'gender', 'school', 'location', 'mobile', 'email', 'classLevel', 'event'];
    const allFieldsFilled = requiredFields.every((field) => formData[field]?.trim() !== '');


    const isDisabled = !allFieldsFilled || !formData.verified;

    
  }, [formData, isVerified])

  const handleVerifyOtp = () => {

    if (otp == storedOtp) {
      const updatedFormData = {
        ...formData,
        verified: true
      };
      setFormData(updatedFormData);
      dispatch(storeFormData(updatedFormData));
      dispatch(clearOtp());
      setIsOtpVerified(true);
      alert("OTP verified successfully!");
    } else {
      alert("Invalid OTP. Please try again.");
    }
  };
  const handleSendOTP = async () => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!formData.email) {
      alert("Please enter a valid email address before verifying.");
      return;
    }
    const { name, gender, school, location, mobile, email, classLevel, event, } = formData;
    if (!name || !gender || !school || !location || !mobile || !email || !classLevel || !event) {
      alert("Please fill in all required fields.");
      return;
    }
    dispatch(storeFormData(formData))


    try {
      const randomOtp = Math.floor(100000 + Math.random() * 900000); // Generate 6-digit OTP
      setGeneratedOtp(randomOtp);
      const timestamp = Date.now();
      setIsOtpSent(true);

      setIsResendDisabled(true);
      // Update formData with the generated OTP
      const updatedFormData = {
        ...formData,
        otp: randomOtp,
        timestamp,
        timer
      };
      setFormData(updatedFormData);
      dispatch(storeOtp({ otp: randomOtp, timestamp }));

      // Store form data in Redux before sending OTP
      dispatch(storeFormData(updatedFormData));


      // Start 60-second timer


      await sendEventEmail({
        emailId: formData.email, // Use the email entered in the form
        emailData: {
          subject: "Your OTP For Confirmation is Given Below",
          text: `Dear ${formData.name},

Your OTP : ${randomOtp}
If you have any questions, feel free to contact us at siyaramgroups.nextech@gmail.com or +91 8442002022.

Warm regards,
Team StudyBuddy`,
        },
      });

      alert("OTP sent to your email. Please check and verify.");
    } catch (error) {
      alert("Failed to send OTP. Please try again.");
    }
  };

  useEffect(() => {
    setGmail(formData.email);

  }, [formData.email,]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!isVerified) {
    //   alert("Please verify your email before submitting.");
    //   return;
    // }
    if (!formData.event) {

      return;
    }

    try {
      // Convert the event name to camelCase for the document ID
      const camelCaseEvent = formData.event
        .split(" ")
        .map((word, index) =>
          index === 0
            ? word.toLowerCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join("");

      // Add participant data to the `Festparticipate` collection

      // Define the object you want to store in the `Festparticipate` document
      const additionalData = {
        registrationTimestamp: new Date().toISOString(),

      };

      const documentData = {
        ...formData, // All fields from formData
        additionalData, // Include the additionalData object
      };

      // Generate a random 6-character alphanumeric string
      const generateRandomId = () => {
        const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generate a 6-digit number
        return 'RD' + randomNumber;
      };

      // Generate a custom document ID
      const customDocId = generateRandomId();
  

      // Create a reference with the custom document ID
      const participantRef = doc(collection(firestore, "Festparticipate"), customDocId);

      // Set the document data with the custom document ID
      await setDoc(participantRef, documentData);

      // Get the document ID of the participant
      const participantId = participantRef.id;

      // Reference to the event document in the `Fest-2025` collection
      const eventRef = doc(firestore, "Fest-2025", camelCaseEvent);

      // Update the event document to add the participant ID under the specific classLevel
      await setDoc(
        eventRef,
        {
          [(formData.classLevel).trim()]: arrayUnion(participantId), // Dynamically add participant ID to the relevant classLevel array
        },
        { merge: true } // Use merge to avoid overwriting existing data
      );
     
      await sendEventEmail({
        emailId: formData.email, // Use the email entered in the form
        emailData: {
          subject: " Congratulations! Your Registration for Rudra is Successfully Completed",
          text: `Dear ${formData.name},

We are thrilled to inform you that your registration for Rudra in ${formData.event} has been successfully completed!

To make your participation seamless, we have provided you with a unique Invitation Code for the quiz competition:

Your code for quiz : ${participantId}

Event description: ${eventDescriptions[formData.event]}

Please ensure  it is essential for your participation in the quiz and other activities.

Why Rudra?

The Rudra event is not just a competition; it is a platform to unleash your potential, celebrate creativity, and encourage innovative thinking. Inspired by the strength and energy of Lord Shiva, Rudra aims to instill confidence, enhance problem-solving skills, and foster teamwork among students.

We believe that participating in this event will help you sharpen your knowledge, boost your confidence, and leave a positive impact on your mind. It’s an opportunity to learn, grow, and shine alongside your peers.

Best Wishes:

On behalf of the entire Rudra organizing team, we extend our best wishes for your participation. We are delighted to have you as part of this grand event and cannot wait to witness the brilliance you will bring to the stage.

Get ready to make memories, showcase your talents, and experience a day filled with excitement, learning, and inspiration!

If you have any questions, feel free to contact us at siyaramgroups.nextech@gmail.com or +91 8442002022.

Looking forward to seeing you at Rudra!

Warm regards,
Team StudyBuddy
Siyaram NexTech`,
        },
      });



      //        sendOTPMessage({
      //         mobile: formData.mobile, // Include the mobile number from the form
      //         text: `Dear ${formData.name},
      //       Thank you for registering for Rudra! To complete your registration, To make your participation seamless, we have provided you with a unique Invitation Code for the
      // quiz competition:

      //       Your code for the quiz:${formData.mobile}

      //       Please do not share it with anyone for security reasons.

      //       If you did not request this, please contact us immediately at siyaramgroups.nextech@gmail.com or +91 8442002022.

      //       Warm regards,  
      //       Rudra Team`,
      //       });


      // alert("successfully Registered!")

      dispatch(deleteFormData());
      // Reset the form after successful submission
      setFormData({
        name: "",
        gender: "",
        school: "",
        location: "",
        mobile: "",
        email: "",
        additionalInfo: "",
        classLevel: "",
        event: "",
      });
alert("successfully Registered!")
      window.location.reload();
    } catch (error) {

      alert("An error occurred while submitting your registration. Please try again.");
    }


  };


  const styles = {
    container: {
      minHeight: '100vh',
      display: 'flex',
      padding: '1rem',

    },
    formContainer: {
      display: 'flex',
      height: screenType === "desktop" || screenType === "tab" || screenType === "tablet" ? "150vh" : null,
      width: '100%',
      flexDirection: screenType === "phone" || screenType === "smallPhone" ? "column" : "row",
      maxWidth: '1200px',
      margin: 'auto',
      backgroundColor: '#ffffff',
      borderRadius: '1rem',
      overflow: 'hidden',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      '@media (max-width: 1024px)': {
        flexDirection: 'column',
      },
    },
    bannerSection: {
      flex: '1',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      minHeight: '600px',
      '@media (max-width: 1024px)': {
        minHeight: '300px',
      },
      '@media (max-width: 480px)': {
        minHeight: '200px',
      },
    },
    bannerOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '2rem',
    },
    bannerTitle: {
      color: '#ffffff',
      fontSize: '2rem',
      fontWeight: 'bold',
      marginBottom: '1rem',
      '@media (max-width: 480px)': {
        fontSize: '1.5rem',
      },
    },
    bannerText: {
      color: '#ffffff',
      fontSize: '1.5rem',
      lineHeight: '1.6',
      '@media (max-width: 480px)': {
        fontSize: '0.9rem',
      },
    },
    formSection: {
      flex: '1',
      // height:"80%",
      overflow: "scroll",
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      '@media (max-width: 1024px)': {
        padding: '1rem',
      },
    },
    formTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#111827',
      marginBottom: '1.5rem',
      '@media (max-width: 480px)': {
        fontSize: '1.25rem',
      },
    },
    inputGroup: {
      marginBottom: '1rem',
      '@media (max-width: 480px)': {
        marginBottom: '0.75rem',
      },
    },
    label: {
      display: 'block',
      fontSize: '0.875rem',
      fontWeight: '500',
      color: '#374151',
      marginBottom: '0.5rem',
    },
    input: {
      width: '100%',
      padding: '0.625rem',
      borderRadius: '0.5rem',
      border: '1px solid #d1d5db',
      fontSize: '1rem',
      transition: 'border-color 0.2s',
      outline: 'none',
      '@media (max-width: 480px)': {
        padding: '0.5rem',
        fontSize: '0.9rem',
      },
    },
    select: {
      width: '104%',
      padding: '0.625rem',
      borderRadius: '0.5rem',
      border: '1px solid #d1d5db',
      fontSize: '1rem',
      backgroundColor: '#ffffff',
      cursor: 'pointer',
      '@media (max-width: 480px)': {
        padding: '0.5rem',
        fontSize: '0.9rem',
      },
      overflowWrap: "wrap",
    },
    textarea: {
      width: '100%',
      padding: '0.625rem',
      borderRadius: '0.5rem',
      border: '1px solid #d1d5db',
      fontSize: '1rem',
      minHeight: '100px',
      resize: 'vertical',
      '@media (max-width: 480px)': {
        minHeight: '80px',
        padding: '0.5rem',
        fontSize: '0.9rem',
      },
    },
    submitButton: {
      backgroundColor: '#4f46e5',
      color: '#ffffff',
      padding: '0.75rem 1.5rem',
      borderRadius: '0.5rem',
      fontSize: '1rem',
      fontWeight: '500',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      marginTop: '1rem',
      width: '100%',
      '@media (max-width: 480px)': {
        padding: '0.625rem 1.25rem',
        fontSize: '0.9rem',
      },

    },
    submitsButton: {
      padding: "0.5rem 1rem",
      backgroundColor: "#d1d5db",
      backgroundColor: '#4f46e5',
      color: '#ffffff',
      border: "none",
      marginTop: "10px",
      borderRadius: "5px",

    },
    disabledButton: {
      padding: "0.5rem 1rem",
      backgroundColor: "#d1d5db",
      color: "#6b7280",
      border: "none",
      marginTop: "10px",
      borderRadius: "5px",
      cursor: "not-allowed",
    },
    timerText: {
      color: "#6b7280",
      fontSize: "14px",
      marginTop: "0.5rem",
    },
    switchContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      userSelect: "none",
    },
    switch: {
      width: "130px",
      height: "30px",
      background: selectedButton === "student" ? "#007bff" : "#ccc",
      borderRadius: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: selectedButton === "student" ? "flex-start" : "flex-end",
      padding: "5px",
      transition: "all 0.3s ease-in-out",
      position: "relative",
    },
    switchToggle: {
      width: "25px",
      height: "25px",
      background: "#fff",
      borderRadius: "50%",
      position: "absolute",
      left: selectedButton === "student" ? "5px" : "calc(100% - 30px)",
      transition: "left 0.3s ease-in-out",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "10px",
      fontWeight: "bold",
      color: selectedButton === "student" ? "#007bff" : "#555",
    },
    switchText: {
      position: "absolute",
      width: "100%",
      textAlign: "center",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#fff",
      zIndex: 1,
    },
  };
  const eventDescriptions = {
    "Art and Expression Workshop":
      "Unleash your imagination and let emotions flow onto the canvas! Art and Expression Workshop is your chance to express creativity through vibrant art and meaningful painting",
    "Naitik Nukkad": "Bring values to life at Naitik Nukkad! Showcase your creativity and teamwork through skits and group activities that inspire good manners and meaningful life lessons.",
    "Buzzer Battle": "Get ready for the ultimate Buzzer Battle! Match pairs, solve crosswords, and complete series. Be the fastest to buzz in, answer, and score points. Speed and accuracy will be your keys to victory!",
    "Vichar Vatika": "Step into Vichar Vatika, where creativity meets expression. Pick a topic, think deeply, and present your unique perspective after 15 minutes. A perfect stage to showcase your ideas and originality!",
    "Science Sketches": "Bring science to life with Science Sketches! Create and present models that visually represent scientific ideas. Share your innovation, creativity, and understanding on stage in this exciting blend of art and science",
    "Spell Rush": "Welcome to Spell Rush! Compete against teams to spell challenging words. Hit the buzzer first to grab the chance and showcase your spelling proficiency. Sharpen your vocabulary and win the ultimate word battle!",
    "Craft It Quick": "Enter the world of creativity with Craft Combat! Participants will transform colorful paper and random objects into stunning crafts. From sparrows to airplanes, let your imagination fly and create magic",
    "Ad-Mad Show": "Unleash your inner marketer in Ad-Mad Show! Select an item, craft a creative pitch, and present a 1-minute advertisement. With just a buffer time to prepare, can you deliver a persuasive and entertaining ad? Show us your innovation!",
    "Diplomat's Dilemma": "Showcase your diplomatic brilliance in Diplomat's Dilemma! Debate global and national issues, devise creative solutions, and present them after 15 minutes of brainstorming. Be prepared to defend your ideas as ambassadors and decision-makers solving the world's most pressing challenges",
    "Mock parliament": "Dive into the art of policymaking with Mock Parliament! Participants bring laws to life, debating policies and defending viewpoints in a lively session. Challenge your creativity, persuasion, and collaboration as you navigate the political arena.",
    "Tark Shastra": "Step into the arena of ideas with Tark Shashtra! Debate topics that matter, with two teams presenting arguments for and against. Over three rounds, challenge perspectives, defend your stance, and prove the power of your words.",
    "Riddle Royale": "Dive into the world of science with Riddle Royale! Teams answer physics and chemistry questions randomly drawn from a jar. Correct answers earn points, while wrong ones cost you. Winning teams must also explain the concepts. Let the brainy battle begin!",
    "Techno Craft": "Unleash your inner inventor in Techno Craft! Create working models or simulations of groundbreaking scientific concepts like renewable energy or robotics. Present your creation on stage, explaining its mechanics and innovation. Let your tech skills shine!",
    "Waste to Wonder": "Transform trash into treasure in Waste to Wonder! Use your creativity to craft a meaningful object from waste materials. Showcase your innovation and prove that nothing is truly useless when imagination takes over.",
    "Jeevit Samaj": "Express, perform, and inspire in Jeevit Samaj! Showcase your artistry and storytelling abilities while addressing meaningful themes. This is your stage to create a powerful connection and make your message unforgettable.",
    "Tark Vitark": "Engage in a battle of wits in Tark Vitark! Debate compelling topics in teams, arguing both sides over 5 rounds. Hone your articulation, critical thinking, and public speaking as you compete to win hearts and minds",
    "Venture Vision": "Bring your business dreams to life in Venture Vision! Pitch unique ideas supported by financial planning, marketing insights, and operational details. Present your vision, backed by market research and revenue goals, to leave a lasting impact.",
    "Financial Forensics": "Step into the role of a financial detective in Financial Forensics! Analyze balance sheets, pinpoint inaccuracies, and showcase your precision in identifying errors. Prove your mastery of financial problem-solving.",
    "CodeQuest": "Unleash your coding potential in CodeQuest! Tackle unique challenges, demonstrate algorithmic mastery, and craft innovative solutions. Compete with the best, showcasing your skills and creativity to claim the title of CodeQuest champion.",
    "Pitch Battle": "Turn everyday objects into must-have items! In this event, students will pitch the importance of random devices or objects, convincing buyers with compelling arguments and innovative sales strategies. Showcase your creativity and charm!",
    "Eureka Exhibition": "Step into the world of innovation with Eureka Exhibition! Showcase your groundbreaking ideas, inventions, and prototypes that could shape the future. Whether it's a creative gadget, a solution to everyday problems, or an ingenious concept, this event is your stage to present your brilliance. Convince the audience with your vision and let your innovation shine!"
  };
  
  const classEvents = {
    "3rd": ["Art and Expression Workshop", "Naitik Nukkad", "Buzzer Battle"],
    "4th": ["Art and Expression Workshop", "Naitik Nukkad", "Buzzer Battle"],
    "5th": ["Art and Expression Workshop", "Naitik Nukkad", "Buzzer Battle"],
    "6th": ["Vichar Vatika", "Science Sketches", "Spell Rush", "Craft It Quick", "CodeQuest"],
    "7th": ["Vichar Vatika", "Science Sketches", "Spell Rush", "Craft It Quick", "CodeQuest"],
    "8th": ["Vichar Vatika", "Science Sketches", "Spell Rush", "Craft It Quick", "CodeQuest"],
    "9th": ["Ad-Mad Show", "Diplomat's Dilemma", "Tark Shastra", "CodeQuest"],
    "10th": ["Ad-Mad Show", "Diplomat's Dilemma", "Tark Shastra", "CodeQuest"],
    "11th Science": ["Waste to Wonder", "Jeevit Samaj", "Tark Vitark", "Mock Parliament", "CodeQuest", "Riddle Royale", "Techno Craft", "Eureka Exhibition", "CodeQuest", "Venture Vision", "Financial Forensics", "Pitch Battle", "CodeQuest"],
    "12th Science": ["Waste to Wonder", "Jeevit Samaj", "Tark Vitark", "Mock Parliament", "CodeQuest", "Riddle Royale", "Techno Craft", "Eureka Exhibition", "CodeQuest", "Venture Vision", "Financial Forensics", "Pitch Battle", "CodeQuest"],
    "11th Arts": ["Waste to Wonder", "Jeevit Samaj", "Tark Vitark", "Mock Parliament", "CodeQuest", "Riddle Royale", "Techno Craft", "Eureka Exhibition", "CodeQuest", "Venture Vision", "Financial Forensics", "Pitch Battle", "CodeQuest"],
    "12th Arts": ["Waste to Wonder", "Jeevit Samaj", "Tark Vitark", "Mock Parliament", "CodeQuest", "Riddle Royale", "Techno Craft", "Eureka Exhibition", "CodeQuest", "Venture Vision", "Financial Forensics", "Pitch Battle", "CodeQuest"],
    "11th Commerce": ["Waste to Wonder", "Jeevit Samaj", "Tark Vitark", "Mock Parliament", "CodeQuest", "Riddle Royale", "Techno Craft", "Eureka Exhibition", "CodeQuest", "Venture Vision", "Financial Forensics", "Pitch Battle", "CodeQuest"],
    "12th Commerce": ["Waste to Wonder", "Jeevit Samaj", "Tark Vitark", "Mock Parliament", "CodeQuest", "Riddle Royale", "Techno Craft", "Eureka Exhibition", "CodeQuest", "Venture Vision", "Financial Forensics", "Pitch Battle", "CodeQuest"],
  };

  const handleSchoolInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile' && !/^[0-9]*$/.test(value)) {

      return; // Prevent non-numeric input
    }

    const updatedSchoolFormData = {
      ...schoolFormData,
      [name]: value,
    };

    setSchoolFormData(updatedSchoolFormData);
  };
 
const filteredObj = (allowedKeys) => {
  if (!allowedKeys || !eventDescriptions) {
    return {}; // Return an empty object if allowedKeys or eventDescriptions is undefined
  }

  return Object.fromEntries(
    Object.entries(eventDescriptions).filter(([key]) => allowedKeys.includes(key))
  );
};

  const [selectedSchool, setSelectedSchool] = useState('');

  const handleSchoolChange = (event) => {
    const selectedSchool = event.target.value;

    // Update formData with the selected school
    const updatedFormData = {
      ...formData,
      school: selectedSchool,
    };
    setFormData({
      ...formData,
      school: selectedSchool,
    });
    setSelectedSchool(selectedSchool);  // Update local state for selected school
    dispatch(storeFormData(updatedFormData));
  };
  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };
  const handleEmailEmpty = () => {
    const updatedFormData = {
      ...formData,
    };
    updatedFormData.email = ''
    updatedFormData.otp = ''
    setFormData({
      ...updatedFormData
    });
    setIsResendDisabled(false)
    setIsOtpSent(false)
    setTimer(60)
    dispatch(storeFormData(updatedFormData));
  }


  const [schoolFormData, setSchoolFormData] = useState({
    schoolname: '',
    strength: '',
    location: '',
    mobile: '',
    email: ''
  });



  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <div style={styles.bannerSection}>
          <div style={styles.bannerOverlay}>
            <h1 style={styles.bannerTitle}>RUDRA{`‘`}25</h1>
            <p style={styles.bannerText}>
              "We aim to nurture talents, create memories and inspire everyone to dream bigger."
            </p>
          </div>
        </div>

        <div style={styles.formSection}>
          <h2 style={styles.formTitle}>Event Registration</h2>
        
         
            <form onSubmit={handleSubmit}>
              <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="name">Full Name</label>
                <input
                  style={styles.input}
                  type="text"
                  id="name"
placeholder='Enter Full Name'
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}

                />
              </div>

              <div>
                <label htmlFor="dropdown" style={styles.label}>Select Your School </label>
                <select

                  style={styles.select}
                  value={formData.school}
                  onChange={handleSchoolChange}
                >
                  <option value=""> Select Your School</option>
                  {roles.map((option, index) => (
                    <option key={index} value={option.value}>
                      <p>
                        {option.value}
                      </p>
                    </option>
                  ))}
                </select>

              </div>
              <div style={{ ...styles.inputGroup, marginTop: '1rem' }}>
                <label style={styles.label} htmlFor="classLevel">Select Class</label>
                <select
                  style={styles.select}
                  id="classLevel"
                  name="classLevel"
                  value={formData.classLevel}
                  onChange={handleInputChange}

                >
                  <option value="">Select a Class</option>
                  {Object.keys(classEvents).map((classLevel) => (
                    <option key={classLevel} value={classLevel}>
                      {classLevel}
                    </option>
                  ))}
                </select>
              </div>

              <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="event">Select Event</label>

                {formData.classLevel ?

                  <div style={{ width: '100%', }}>
                    <ReusableDropdown
                      selected={formData.event} // Reset the dropdown when classLevel changes
                      options={filteredObj(classEvents[formData.classLevel]) || []} // Pass the event descriptions array as options
                      onSelect={handleSelectEvent}
                    />
                  </div>
                  :
                  <ReusableDropdown
                    options={[]} // Pass the event descriptions array as options
                    onSelect={""}
                    disabled={true}
                  />
                }


                {formData.event && (
                  <div >
                    <p style={{
                      backgroundColor: "#f7fafc", // Light gray background
                      color: "#2d3748",           // Dark gray text
                      fontSize: "14px",
                      width: "100%",      // Small text
                      padding: "10px",            // Padding inside the box
                      borderRadius: "8px",        // Rounded corners
                      border: "1px solid #e2e8f0", // Light gray border
                      marginTop: "10px",          // Space above the 
                    }}>
                      <p style={{ fontSize: "20px", fontWeight: 700 }} >{formData.event}</p>
                      {eventDescriptions[formData.event]}
                    </p>
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: '#555' }}>
                <button
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',

                    borderRadius: '50%',
                    width: '14px',
                    height: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '8px',
                    cursor: 'pointer',
                    margin: '0 5px 0 0', // Small spacing to the right of the button
                  }}
                >
                  i
                </button>
                <p style={{ margin: 0 }}>First, select a class, then choose your event.</p>
              </div>

              {/* Other input fields */}
              <div style={{ ...styles.inputGroup, marginTop: '1rem' }}>
                <label style={styles.label} htmlFor="gender">Gender</label>
                <select
                  style={styles.select}
                  id="gender"

                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}

                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="location">Address</label>
                <input
                  style={styles.input}
                  type="text"
                  id="location"
                  placeholder='Enter Address'
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}

                />
              </div>

              <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="mobile">Mobile Number</label>
                <input
                  style={styles.input}
                  type="tel"
                  id="mobile"
                  placeholder='Enter Mobile Number'
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}

                  pattern="[0-9]*" // Only allow numbers
                  maxLength="10"
                  minLength={10}
                />
              </div>

              <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="email">Email Address</label>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                  <input
                    style={styles.input}
                    type="email"
                    placeholder='Enter Email Address'
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={!isOtpVerified && formData.otp}
                  />
               
                </div>
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="additionalInfo">Additional Information</label>
                <textarea
                  style={styles.textarea}
                  id="additionalInfo"
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleInputChange}
                  placeholder="Any special message for us?"
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: '#555' }}>
                <button
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',

                    borderRadius: '50%',
                    width: '14px',
                    height: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '8px',
                    cursor: 'pointer',
                    margin: '0 5px 0 0', // Small spacing to the right of the button
                  }}
                >
                  i
                </button>
                <p style={{ margin: 0 }}> Ensure all required fields are filled out before proceeding to register.</p>
              </div>


              <button
                style={{
                  ...styles.submitButton, backgroundColor:  '#4f46e5',
                  cursor: 'pointer',
                }}
                type="submit"
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#4338ca';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = '#4f46e5';
                }}

                // disabled={isSubmitDisabled}
              >
                Register Now
              </button>
            </form>
         

        </div>
      </div>

    </div>
  );
}

export default FestInputForm;
